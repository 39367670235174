// PageDescription: 닥터나우 홈 페이지
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import React from 'react';

import LandingTemplate from '@/components/templates/landing/LandingTemplate';
import LandingLayout from '@/components/templates/landing/layout/LandingLayout';
import { CustomPageProps } from '@/types/app';

const MainPage = ({ client }: CustomPageProps) => {
  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://file.doctornow.co.kr/landing/aos.css"
        />
      </Head>
      <NextSeo
        languageAlternates={[
          {
            hrefLang: 'ja',
            href: 'https://doctornow.jp',
          },
          {
            hrefLang: 'ja-JP',
            href: 'https://doctornow.jp',
          },
          {
            hrefLang: 'ko',
            href: 'https://doctornow.co.kr',
          },
          {
            hrefLang: 'ko-KR',
            href: 'https://doctornow.co.kr',
          },
          {
            hrefLang: 'x-default',
            href: 'https://doctornow.co.kr',
          },
        ]}
      />
      <LandingTemplate client={client} />
    </>
  );
};

export default MainPage;

MainPage.Layout = LandingLayout;
